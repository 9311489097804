import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Others = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Other Drugs" }) {
        learnMore {
          raw
        }
        paragraph {
          raw
        }
        paragraph2 {
          raw
        }
      }
    }
  `);

  const { contentfulOneOhOne } = data;
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);
  const paragraph = JSON.parse(contentfulOneOhOne.paragraph.raw);
  const paragraph2 = JSON.parse(contentfulOneOhOne.paragraph2.raw);
  const title = contentfulOneOhOne.title;

  return (
    <section className="section">
      <div className="content">
        <div className="container container-block">
          <div className="column pt-6 pb-4">
            <h2 className="is-size-2">{title}</h2>
            {renderRichText(paragraph)}
            <div style={{ wordBreak: 'break-all' }}>
              <p className="has-text-left">{renderRichText(learnMore)}</p>
            </div>
            <div
              className="mt-6"
              id="Signs & Symptoms of Addiction"
              style={{ scrollMarginTop: '6.5rem' }}
            >
              <h3 className="is-size-2">Signs and Symptoms of Addiction</h3>
              {renderRichText(paragraph2)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Others;
