import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import '../../pages/page-styles.scss';

const BrainDev = () => {
  const data = useStaticQuery(graphql`
    {
      buildingBlocks: contentfulOneOhOne(
        title: { eq: "Building Blocks of the Brain" }
      ) {
        drugGraphic {
          file {
            url
          }
          title
        }
        title
        paragraph {
          raw
        }
      }
      experiences: contentfulOneOhOne(
        title: { eq: "Our Experiences Shape Our Brains" }
      ) {
        title
        drugGraphic {
          file {
            url
          }
          title
        }
        learnMore {
          raw
        }
        paragraph {
          raw
        }
      }
      importantParts: contentfulOneOhOne(
        title: { eq: "Two Important Parts of the Brain" }
      ) {
        drugGraphic {
          file {
            url
          }
          title
        }
        drugGraphic2 {
          file {
            url
          }
          title
        }
        title
        paragraph {
          raw
        }
        paragraph2 {
          raw
        }
      }
      natural: contentfulOneOhOne(title: { eq: "Natural Reward Pathway" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        title
        paragraph {
          raw
        }
      }
      substance: contentfulOneOhOne(
        title: { eq: "Substance Use & The Brain" }
      ) {
        drugGraphic {
          file {
            url
          }
          title
        }
        title
        paragraph {
          raw
        }
      }
    }
  `);

  const {
    buildingBlocks,
    experiences,
    importantParts,
    natural,
    substance,
  } = data;

  return (
    <div className="has-background-black">
      <section className="section anchor-teen pt-2" id="Teen Brain Development">
        <div className="container">
          <div>
            <h2 className="is-size-2 has-text-white mt-2">
              Teen Brain Development
            </h2>
            <h5 className="is-size-5 has-text-white">{buildingBlocks.title}</h5>
            <div className="is-hidden-mobile">
              <div className="pt-4 pb-3">
                <div className="has-text-centered">
                  <figure className="image">
                    <img
                      className="thc-graph"
                      src={buildingBlocks.drugGraphic.file.url}
                      alt={buildingBlocks.drugGraphic.title}
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="pt-2 mb-6 has-text-white">
              {renderRichText(JSON.parse(buildingBlocks.paragraph.raw))}
            </div>
            <h5 className="is-size-5 pb-2 has-text-white">
              {experiences.title}
            </h5>
            <section className="section has-text-white pt-0">
              <div className="container">
                <div>
                  <div className="has-text-centered">
                    <img
                      className="thc-graph"
                      src={experiences.drugGraphic.file.url}
                      alt={experiences.drugGraphic.title}
                    />
                  </div>
                  {renderRichText(JSON.parse(experiences.paragraph.raw))}
                  <div className="white">
                    {renderRichText(JSON.parse(experiences.learnMore.raw))}
                  </div>
                </div>
              </div>
            </section>
            <h5 className="is-size-2 has-text-white">{importantParts.title}</h5>
            <div className="columns has-text-white">
              <div className="column">
                <img
                  className="thc-graph"
                  src={importantParts.drugGraphic.file.url}
                  alt={importantParts.drugGraphic.title}
                />
                <div className="white">
                  {renderRichText(JSON.parse(importantParts.paragraph.raw))}
                </div>
              </div>
              <div className="column">
                <img
                  className="thc-graph"
                  src={importantParts.drugGraphic2.file.url}
                  alt={importantParts.drugGraphic2.title}
                />
                <div className="white">
                  {renderRichText(JSON.parse(importantParts.paragraph2.raw))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div>
            <div className="has-text-centered">
              <img
                className="thc-graph"
                src={natural.drugGraphic.file.url}
                alt={natural.drugGraphic.title}
              />
            </div>
            <div className="pt-2 mb-6 has-text-white">
              {renderRichText(JSON.parse(natural.paragraph.raw))}
            </div>
          </div>
        </div>
      </section>
      <section className="section anchor" id="Substance Use & the Brain">
        <div className="container">
          <div>
            <h2 className="is-size-2 pb-2 has-text-white">{substance.title}</h2>
            <div className="has-text-centered">
              <img
                className="thc-graph"
                src={substance.drugGraphic.file.url}
                alt={substance.drugGraphic.title}
              />
            </div>
            <div className="pt-2 mb-6 has-text-white">
              {renderRichText(JSON.parse(substance.paragraph.raw))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrainDev;
