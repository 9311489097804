import React from 'react';
import './styles.scss';

const PreventCard = ({ number, header, content }) => {
  return (
    <div className="column is-5">
      <div className="card p-4">
        <h1 className="is-size-1 has-text-primary">{number}</h1>
        <h3 className="is-size-3 my-0">{header}</h3>
        <div className="card-content">{content}</div>
      </div>
    </div>
  );
};

export default PreventCard;
