import React from 'react';
import Layout from '../components/layout';
import OneOhOneComponent from '../components/one-oh-one/';

const headerText = 'SUBSTANCE USE 101';

const OneOhOne = () => {
  return (
    <Layout
      headerText={headerText}
      heroFont="has-text-white"
      heroBg="dark-hero"
    >
      <OneOhOneComponent />
    </Layout>
  );
};

export default OneOhOne;
