import React from 'react';
import BrainDev from './BrainDev';
import Alcohol from './Alcohol';
import Marijuana from './Marijuana';
import Vaping from './Vaping';
import Nicotine from './Nicotine';
import Industry from './Industry';
import Prevention from './Prevention';
import TeenBrain from './TeenBrain';
import Others from './Others';
import './styles.scss';

const OneOhOneComponent = () => {
  return (
    <div className="content">
      <TeenBrain />
      <BrainDev />
      <section
        className="py-5 has-background-info anchor-teen"
        id="Health Effects"
      >
        <div className="has-text-centered">
          <h2 className="is-size-2 mb-0 px-2">
            Health Effects of The Most Commonly Used Substances by Teens and
            Young Adults
          </h2>
        </div>
      </section>
      <Alcohol />
      <Marijuana />
      <Nicotine />
      <Vaping />
      <Others />
      <Industry />
      <Prevention />
    </div>
  );
};

export default OneOhOneComponent;
