import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import '../../pages/page-styles.scss';

const ImageSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Industry Influence" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        title
        reference {
          raw
        }
        learnMore {
          raw
        }
        paragraph {
          raw
        }
      }
    }
  `);

  const { contentfulOneOhOne } = data;
  const image = contentfulOneOhOne.drugGraphic.file.url;
  const title = contentfulOneOhOne.drugGraphic.title;
  const mainTitle = contentfulOneOhOne.title;
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);
  const paragraph = JSON.parse(contentfulOneOhOne.paragraph.raw);

  return (
    <section className="section anchor-intro pt-0" id="Industry Influence">
      <div className="content">
        <div className="container">
          <div>
            <h2 className="is-size-2 mb-4">{mainTitle}</h2>
            <div className="pt-4 pb-3">
              <div className="has-text-centered">
                <img src={image} alt={title} className="thc-graph" />
              </div>
              <div style={{ wordBreak: 'break-word' }}>
                <p className="is-size-7">
                  Sources: Stanford Research Into the Impact of Tobacco
                  Advertising,<sup>16</sup> & Centers for Disease Control and
                  Prevention<sup>17</sup>
                </p>
              </div>
            </div>
            <div className="pt-2 mb-2">{renderRichText(paragraph)}</div>
            <div>
              <p className="has-text-left">{renderRichText(learnMore)}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
