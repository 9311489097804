import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Alcohol = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Alcohol" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        reference {
          raw
        }
        learnMore {
          raw
        }
      }
    }
  `);

  const { contentfulOneOhOne } = data;
  const alcImage = contentfulOneOhOne.drugGraphic.file.url;
  const alcTitle = contentfulOneOhOne.drugGraphic.title;
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);

  return (
    <section className="section">
      <div className="container">
        <div className="mb-6 pt-4">
          <div className="content">
            <div className="has-text-centered">
              <img className="thc-graph" src={alcImage} alt={alcTitle} />
            </div>
            <p className="is-size-7 has-text-centered">
              Source: U.S. Department of Health and Human Services
              <sup>13</sup>
            </p>
            <p className="has-text-left">{renderRichText(learnMore)}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Alcohol;
