import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Nicotine = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Nicotine" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        learnMore {
          raw
        }
        paragraph {
          raw
        }
      }
    }
  `);
  const { contentfulOneOhOne } = data;
  const nicotineImage = contentfulOneOhOne.drugGraphic.file.url;
  const nicotineTitle = contentfulOneOhOne.drugGraphic.title;
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);
  const para = JSON.parse(contentfulOneOhOne.paragraph.raw);

  return (
    <section className="section">
      <div className="container">
        <div className="mb-6 pt-4">
          <div className="content">
            <div className="has-text-centered">
              <img
                src={nicotineImage}
                alt={nicotineTitle}
                className="thc-graph"
              />
            </div>
            <div className="mt-4">
              {renderRichText(para)}
              {renderRichText(learnMore)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nicotine;
