import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import '../../pages/page-styles.scss';
import renderRichText from '../../utils/renderRichText';

const TeenBrain = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Rates of Teen Use" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        title
        learnMore {
          raw
        }
        paragraph {
          raw
        }
      }
    }
  `);

  const { contentfulOneOhOne } = data;
  const image = contentfulOneOhOne.drugGraphic.file.url;
  const title = contentfulOneOhOne.drugGraphic.title;
  const mainTitle = contentfulOneOhOne.title;
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);
  const paragraph = JSON.parse(contentfulOneOhOne.paragraph.raw);

  return (
    <div className="section">
      <div className="container anchor-rates" id="Rates of Teen Use">
        <div className="content">
          <div className="has-text-centered">
            <div className="content has-text-left">
              <h2 className="is-size-2">{mainTitle}</h2>
              {renderRichText(paragraph)}
            </div>
            <div className="has-text-centered">
              <img src={image} alt={title} className="trends-graph" />
            </div>
            <p className="is-size-7">
              Source: Adapted from{' '}
              <a
                href="https://pediatrics.aappublications.org/content/early/2020/11/06/peds.2020-007187/tab-article-info"
                target="_blank"
                rel="noreferrer"
              >
                Trends in Substance Nonuse by High School Seniors: 1975–2018
              </a>
              <sup>10</sup>
            </p>
            <p className="has-text-left" style={{ wordBreak: 'break-all' }}>
              {renderRichText(learnMore)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeenBrain;
