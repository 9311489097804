import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import './styles.scss';

const Marijuana = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Marijuana" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        drugGraphic2 {
          file {
            url
          }
          title
        }
        reference {
          raw
        }
        learnMore {
          raw
        }
        paragraph {
          raw
        }
        paragraph2 {
          raw
        }
      }
    }
  `);
  const { contentfulOneOhOne } = data;
  const marImage = contentfulOneOhOne.drugGraphic.file.url;
  const thcGraph = contentfulOneOhOne.drugGraphic2.file.url;
  const marTitle = contentfulOneOhOne.drugGraphic.title;
  const thcTitle = contentfulOneOhOne.drugGraphic2.title;
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);
  const para = JSON.parse(contentfulOneOhOne.paragraph.raw);
  const paraTwo = JSON.parse(contentfulOneOhOne.paragraph2.raw);

  return (
    <section
      className="section has-text-white"
      style={{ backgroundColor: '#010101' }}
    >
      <div className="container">
        <div className="pt-4">
          <div className="content">
            <div className="has-text-centered">
              <img src={marImage} alt={marTitle} className="thc-graph" />
            </div>
            <div className="mt-6">
              <h3 className="is-size-2 has-text-white pt-4">
                Strength and Potency
              </h3>
              <div className="pt-4 pb-3">
                <div className="has-text-centered">
                  <img src={thcGraph} alt={thcTitle} className="thc-graph" />
                </div>
                <p className="has-text-centered is-size-7">
                  Source: Adapted from NIDA and data from the Potency Monitoring
                  Program, Quarterly Report #146<sup>14</sup>
                </p>
              </div>
              {renderRichText(para)}
              <h5
                className="is-size-5 has-text-white"
                style={{ marginBottom: 0 }}
              >
                Marijuana Extracts
              </h5>
              {renderRichText(paraTwo)}
              <div style={{ wordBreak: 'break-all' }} className="white">
                {renderRichText(learnMore)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Marijuana;
