import React from 'react';
import PreventCard from './PreventCard';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import '../../pages/page-styles.scss';

const Prevention = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPreventionCard {
        edges {
          node {
            id
            header
            content {
              raw
            }
          }
        }
      }
    }
  `);

  const { edges } = data.allContentfulPreventionCard;
  return (
    <>
      <div
        id="Better Addressing Substance Use as a Society"
        className="anchor-teen"
      >
        <section className="section py-0">
          <div className="container">
            <div className="column">
              <h2 className="is-size-2">
                How can we, as a society, better address substance use?
              </h2>
            </div>
          </div>
        </section>
        <section className="section pt-0">
          <div>
            <div className="columns">
              <div className="column is-1 is-hidden-mobile" />
              <PreventCard
                number={1}
                header={edges[0].node.header}
                content={
                  <div className="content">
                    {renderRichText(JSON.parse(edges[0].node.content.raw))}
                  </div>
                }
              />
              <PreventCard
                number={2}
                header={edges[1].node.header}
                content={
                  <div className="content">
                    {renderRichText(JSON.parse(edges[1].node.content.raw))}
                  </div>
                }
              />
              <div className="column is-1 is-hidden-mobile" />
            </div>
            <div className="columns">
              <div className="column is-1" />
              <PreventCard
                number={3}
                header={edges[2].node.header}
                content={
                  <div className="content">
                    {renderRichText(JSON.parse(edges[2].node.content.raw))}
                  </div>
                }
              />
              <PreventCard
                number={4}
                header={edges[3].node.header}
                content={
                  <div className="content">
                    {renderRichText(JSON.parse(edges[3].node.content.raw))}
                  </div>
                }
              />
              <div className="column is-1" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Prevention;
