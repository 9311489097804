import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';

const Vaping = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulOneOhOne(title: { eq: "Vaping" }) {
        drugGraphic {
          file {
            url
          }
          title
        }
        title
        learnMore {
          raw
        }
        paragraph {
          raw
        }
        paragraph2 {
          raw
        }
      }
    }
  `);

  const { contentfulOneOhOne } = data;
  const image = contentfulOneOhOne.drugGraphic.file.url;
  const title = contentfulOneOhOne.drugGraphic.title;
  const mainTitle = contentfulOneOhOne.title;
  // const ref = JSON.parse(contentfulOneOhOne.reference.raw);
  const learnMore = JSON.parse(contentfulOneOhOne.learnMore.raw);
  const paragraph = JSON.parse(contentfulOneOhOne.paragraph.raw);
  const paragraph2 = JSON.parse(contentfulOneOhOne.paragraph2.raw);

  return (
    <>
      <section
        className="section has-text-white"
        style={{ backgroundColor: '#010101' }}
      >
        <div className="content">
          <div className="container container-block">
            <div>
              <h2 className="is-size-2 has-text-white">{mainTitle}</h2>
              <div>{renderRichText(paragraph)}</div>
              <div className="columns mt-6 has-text-centered">
                <div className="column is-4 is-full-mobile">
                  <img src={image} alt={title} className="vaping-img" />
                </div>
                <div className="column">
                  <p className="has-text-left has-text-white">
                    {renderRichText(paragraph2)}
                  </p>
                  <div className="has-text-left white">
                    {renderRichText(learnMore)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vaping;
